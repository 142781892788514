import React from "react"
import "./styles.css"
import LoadDrop from "../../../images/load-drop.png"
import LoadChart from "../../../images/load-chart.png"
import WhiteLogo from "../../../images/logo_white.svg"
import LoadReportChart from "../../../images/load-report-chart.png"
import LoadCircles from "../../../images/load-many-circles.png"
import LoadLinesChart from "../../../images/load-lines-chart.png"
import LoadWorld from "../../../images/load-world.png"
import { useTranslation } from "../../../utils"
import { openHubspotChat } from "../../../utils"

const LoadHowSection = () => {
  const { t } = useTranslation()

  return (
    <section className="load-how-section">
      <div className="container">
        <div className="load-how-section__first">
          <h4>{t("Main features")}</h4>
          <h3>{t("How we do its")}</h3>
        </div>
        <div className="load-how-section__cards">
          <div className="load-how-section__list">
            <div className="load-how-section__card load-how-section__card_sm dark-card">
              <p>{t("Test scripts creation")}</p>
              <img src={LoadDrop} alt="img" />
            </div>
            <div className="load-how-section__card load-how-section__card_sm dark-card">
              <p>{t("Test scripts creation")}</p>
              <img src={LoadChart} alt="img" />
            </div>
            <div className="load-how-section__card load-how-section__card_lg dark-card">
              <div className="load-how-section__card-flex">
                <div className="load-how-section__card-flex-left">
                  <img src={WhiteLogo} alt="logo" />
                  <h4 className="load-how-section__card-lg-title">
                    {t("Load test report")}
                  </h4>
                  <p>
                    {t(
                      "Get a detailed test report for meticulous performance tuning."
                    )}
                  </p>
                  <button className="load-how-section__card-btn-wrapper">
                    <div className="load-how-section__card-btn">
                      {t("Download example")}
                    </div>
                  </button>
                </div>
                <img src={LoadReportChart} alt="img" />
              </div>
            </div>
          </div>

          <div className="load-how-section__list-2">
            <div className="load-how-section__card load-how-section__card_sm dark-card">
              <p>{t("Simulating real user behavior")}</p>
              <img src={LoadCircles} alt="img" />
            </div>
            <div className="load-how-section__card load-how-section__card_sm dark-card">
              <p>{t("Identifying bottlenecks")}</p>
              <img src={LoadLinesChart} alt="img" />
            </div>
            <div className="load-how-section__card load-how-section__card_lg dark-card">
              <div className="load-how-section__card-flex">
                <div className="load-how-section__card-flex-left">
                  <h4 className="load-how-section__card-lg-title">
                    {t("Distributed load testing")}
                  </h4>
                  <p>
                    {t(
                      "Creates a large number of virtual users from different locations using different hosts."
                    )}
                  </p>
                  <button
                    className="load-how-section__card-btn wt-button wt-button--red-blue "
                    onClick={openHubspotChat}
                  >
                    {t("Let's chat")}
                  </button>
                </div>

                <img src={LoadWorld} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoadHowSection
