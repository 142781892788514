import React from "react"
import "./styles.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import Footer from "../../sections/footer"
import LoadSection from "../../sections/load/load-main"
import LoadCardsSection from "../../sections/load/load-cards"
import LoadHowSection from "../../sections/load/load-how"
import LoadFindSection from "../../sections/load/load-find"

const LoadPage = () => {
  return (
    <Layout>
      <SEO title="WTLoad - reliable load testing platform" />
      <Header theme="dark" />
      <div className="load-wrapper">
        <div className="load-section__circles">
          <div className="load-section__circle load-section__circle_1"></div>
          <div className="load-section__circle load-section__circle_2"></div>
          <div className="load-section__circle load-section__circle_3"></div>
        </div>
        <LoadSection />
        <LoadCardsSection />
        <LoadHowSection />
        <LoadFindSection />
      </div>
      <Footer />
    </Layout>
  )
}

export default LoadPage
