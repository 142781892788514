import React from "react"
import "./styles.css"
import LinesImg from "../../../images/load-lines.png"
import { useTranslation } from "../../../utils"
import ApplyForm from "../../../components/apply-form"
import { requestLoadDemo } from "../../../api/demo-request"
import { useAnalytics } from "../../../hooks/useTracking"

const LoadSection = () => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  return (
    <section className="load-section">
      <div className="load-section__offer">
        <h3>{t("WTLoad makes your service stronger")}</h3>
        <h2 className="load-section__offer-title">
          {t("WTLoad - reliable load testing platform")}
        </h2>
        <p>
          {t(
            "Load testing tool that allows you to identify the presence and cause of weaknesses in system performance"
          )}
        </p>

        <ApplyForm
          onOpen={analytics.clickGetDemo}
          analyticsOnSubmit={analytics.clickSend}
          title="Request a demo"
          request={requestLoadDemo}
        >
          <button className="load-section__offer-btn">{t("Get demo")}</button>
        </ApplyForm>
      </div>
      <div className="load-way-section">
        <img className="load-section__lines" src={LinesImg} alt="Img" />
        <div className="load-way-section__offer">
          <h3>{t("The best way to find out whether a site is resilient")}</h3>
          <p>
            {t(
              "Build and test real end-user experiences across tens of thousands of browsers with WTLoad tool. Get a reliable picture of the performance status of your application in no time."
            )}
          </p>
        </div>
      </div>
    </section>
  )
}

export default LoadSection
