import React from "react"
import "./styles.css"
import SpeedImg from "../../../images/load-speed.png"
import CirclesImg from "../../../images/load-circles1.png"
import LoadChart1 from "../../../images/load-chart1.png"
import LoadChart2 from "../../../images/load-chart2.png"
import LoadChart3 from "../../../images/load-chart3.png"
import { useTranslation } from "../../../utils"

const LoadCardsSection = () => {
  const { t } = useTranslation()

  return (
    <section className="load-cards-section">
      <div className="container">
        <div className="load-cards-section__first">
          <div className="load-cards-section__first-card load-cards-section__first-card_1 dark-card">
            <img src={SpeedImg} alt="img" />
            <h4>{t("Stress test")}</h4>
            <p>
              {t(
                "Software performance study to check the system's ability to regenerate after a stress state. Test is done under stress conditions, when the load exceeds the maximum allowable values."
              )}
            </p>
          </div>
          <div className="load-cards-section__first-card load-cards-section__first-card_2 dark-card">
            <img src={CirclesImg} alt="img" />
            <h4>{t("DDos attack")}</h4>
            <p>
              {t(
                "Software stability investigation. Performed in long-term use mode to detect memory leaks, server restart and other aspects affecting the load."
              )}
            </p>
          </div>
        </div>
        <div className="load-cards-section__last dark-card">
          <div className="load-cards-section__last-offer">
            <h4>{t("What is load testing?")}</h4>
            <p>
              {t(
                "A type of testing performed to verify how an application or software performs in terms of stability under normal and peak usage. The purpose of the test is to identify and eliminate application performance bottlenecks. At loads, the stress testing method can be used (imitation of real users)."
              )}
            </p>
          </div>
          <div className="load-cards-section__last-charts">
            <h4 className="load-cards-section__charts-title">
              {t("Testing overview")}
            </h4>
            <div className="load-cards-section__charts-wrapper">
              <div className="load-cards-section__charts-block">
                <div className="load-cards-section__chart-title">
                  {t("Total loads")}
                </div>
                <div className="load-cards-section__chart-number">94.476</div>
                <img src={LoadChart1} alt="img" />
                <div className="load-cards-section__chart-statistics">
                  <div className="load-cards-section__chart-statistics-item">
                    <span className="load-cards-section__chart-green">
                      4453
                    </span>{" "}
                    {t("per shot")}
                  </div>
                  <div className="load-cards-section__chart-statistics-item">
                    <span className="load-cards-section__chart-green">843</span>{" "}
                    {t("today")}
                  </div>
                </div>
              </div>
              <div className="load-cards-section__charts-block">
                <div className="load-cards-section__chart-title">
                  {t("Total loads")}
                </div>
                <div className="load-cards-section__chart-number">2.442</div>
                <img src={LoadChart2} alt="img" />
                <div className="load-cards-section__chart-statistics">
                  <div className="load-cards-section__chart-statistics-item">
                    <span className="load-cards-section__chart-blue">4453</span>{" "}
                    {t("per shot")}
                  </div>
                  <div className="load-cards-section__chart-statistics-item">
                    <span className="load-cards-section__chart-blue">843 </span>{" "}
                    {t("today")}
                  </div>
                </div>
              </div>
              <div className="load-cards-section__charts-block">
                <div className="load-cards-section__chart-title">
                  {t("Total loads")}
                </div>
                <div className="load-cards-section__chart-number">3567</div>
                <img src={LoadChart3} alt="img" />
                <div className="load-cards-section__chart-statistics">
                  <div className="load-cards-section__chart-statistics-item">
                    <span className="load-cards-section__chart-gray">4453</span>{" "}
                    {t("per shot")}
                  </div>
                  <div className="load-cards-section__chart-statistics-item">
                    <span className="load-cards-section__chart-gray">843</span>{" "}
                    {t("today")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoadCardsSection
