import React from "react"
import "./styles.css"
import LoadPercentImg from "../../../images/load-find-pervent.svg"
import LoadLampImg from "../../../images/load-find-lamp.svg"
import LoadGraphImg from "../../../images/load-find-graph.svg"
import LoadSmileImg from "../../../images/load-find-smile.svg"
import LoadCheckImg from "../../../images/load-find-check.svg"
import LoadChartImg from "../../../images/load-find-chart.svg"
import { useTranslation } from "../../../utils"
import { openHubspotChat } from "../../../utils"

const LoadFindSection = () => {
  const { t } = useTranslation()
  return (
    <section className="load-find-section">
      <div className="container">
        <h3 className="load-find-section__title">{t("Find out")}</h3>
        <div className="load-find-section__wrapper">
          <div className="load-find-section__cards">
            <div className="load-find-section__card">
              <img src={LoadPercentImg} alt="img" />
              <p>
                {t(
                  "How much data can the database handle before the system slows down or crashes?"
                )}
              </p>
            </div>
            <div className="load-find-section__card">
              <img src={LoadGraphImg} alt="img" />
              <p>
                {t(
                  "What is the maximum load your system can handle before failure?"
                )}
              </p>
            </div>
            <div className="load-find-section__card">
              <img src={LoadLampImg} alt="img" />
              <p>{t("Can the system recover from a crash?")}</p>
            </div>
            <div className="load-find-section__card">
              <img src={LoadSmileImg} alt="img" />
              <p>{t("How does the system fail?")}</p>
            </div>
            <div className="load-find-section__card">
              <img src={LoadCheckImg} alt="img" />
              <p>
                {t(
                  "How can the system be compromised and what are the weak points in handling the load?"
                )}
              </p>
            </div>
            <div className="load-find-section__card">
              <img src={LoadChartImg} alt="img" />
              <p>
                {t(
                  "Do you need to solve any problems related to the network, architecture, software?"
                )}
              </p>
            </div>
          </div>
          <div className="load-find-section__offer dark-card">
            <h4>{t("Still think?")}</h4>
            <p>
              {t(
                "You will not need to wait for your site to be displayed and crash. With us it happens instantly."
              )}
            </p>
            <button
              className="wt-button wt-button--red-blue"
              onClick={openHubspotChat}
            >
              {t("Let's chat")}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoadFindSection
